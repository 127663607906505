import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home/Home'
import Place from './Place/Place'
import Login from './Login/Login'
import PlaceEdit from './PlaceEdit/PlaceEdit'

const App = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/place/new" exact>
        <PlaceEdit />
      </Route>
      <Route path="/place/:id" exact>
        <Place />
      </Route>
      <Route path="/place/:id/edit" exact>
        <PlaceEdit />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
    </Switch>
  )
}

export default App
