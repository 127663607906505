import React from 'react'
import './Search.sass'

interface SearchProps {
  value: string
  onChange: (newValue: string) => void
}

const Search = ({ value, onChange }: SearchProps) => {
  return (
    <form className="search">
      <input
        name="search"
        value={value}
        onChange={e => onChange(e.target.value)}
        autoComplete="off"
        placeholder="Buscar..."
      />
    </form>
  )
}

export default Search
