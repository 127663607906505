import React, { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { usePlace } from 'src/hooks/api'
import LoginOrEdit from './LoginOrEdit'
import './Place.sass'

const API_URL = (process.env.APP_URL || 'http://localhost:3000') + '/api'
const GEOAPIFY_API_KEY = process.env.GEOAPIFY_API_KEY

const Place = () => {
  const { id } = useParams()
  const place = usePlace(id)
  const img = `${API_URL}/places/${place.id}/image.jpg`
  const mapLink = 'https://www.google.com/maps/search/?api=1&query='
    + place.location.y + ',' + place.location.x
    + (place.gid ? '&query_place_id=' + place.gid : '')
  const mapImg = 'https://maps.geoapify.com/v1/staticmap?width=300&height=300&zoom=11'
    + '&center=lonlat:' + place.location.x + ',' + place.location.y
    + '&marker=lonlat:' + place.location.x + ',' + place.location.y
    + '&apiKey=' + GEOAPIFY_API_KEY

  return (
    <div className="page place">
      <header>
        <Link to="/">← Volver</Link>
        <h1>
          <span>{place.title}</span>
          <div className={'badge ' + (place.visited ? 'visited' : 'not-visited')}>
            <FormattedMessage id={'badge.' + (place.visited ? 'visited' : 'not-visited')} />
          </div>
        </h1>
        <LoginOrEdit />
      </header>
      <main>
        <div className="image" style={{ backgroundImage: `url(${img})` }} />
        <div className="description">
          {place.description}
        </div>
        <a href={mapLink} target="_blank" className="minimap" style={{ backgroundImage: `url(${mapImg})`}} />
      </main>
      <Helmet>
        <title>{place.title}</title>
        <meta property="og:description" content={place.description.slice(0, 200)} />
        <meta property="og:image" content={img} />
      </Helmet>
    </div>
  )
}

const PlaceWrapper = () =>
  <Suspense fallback={false}>
    <Place />
  </Suspense>

export default PlaceWrapper
