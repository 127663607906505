import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Redirect } from 'react-router-dom'
import { useLoggedIn, useUserActions } from 'src/hooks/api'
import './Login.sass'

const Login = () => {
  const [data, setData] = useState({ username: '', password: '' })
  const [error, setError] = useState()
  const userActions = useUserActions()

  const isLoggedIn = useLoggedIn()
  if (isLoggedIn) return <Redirect to="/" />

  const handleSubmit = async e => {
    e.preventDefault()
    setError(null)
    try {
      await userActions.login(data.username, data.password)
    } catch (e) {
      console.warn(e)
      setError(e?.error || 'Unknown error')
    }
  }

  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value })

  return (
    <form className="page login" onSubmit={handleSubmit}>
      <header>
        <h1><FormattedMessage id="login.title" /></h1>
      </header>
      <label>
        <span><FormattedMessage id="login.username" /></span>
        <input name="username" value={data.username} onChange={handleChange} required />
      </label>
      <label>
        <span><FormattedMessage id="login.password" /></span>
        <input name="password" type="password" value={data.password} onChange={handleChange} required />
      </label>
      <button><FormattedMessage id="login.submit" /></button>
      {error &&
        <p className="error">{error}</p>
      }
    </form>
  )
}

export default Login
