import React, { Suspense, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { usePlace, usePlaceCRUD } from 'src/hooks/api'
import './PlaceEdit.sass'

const API_URL = (process.env.APP_URL || 'http://localhost:3000') + '/api'

const PlaceEdit = () => {
  const { id } = useParams()
  const place = usePlace(id)
  const placeCRUD = usePlaceCRUD()
  const history = useHistory()
  const [title, setTitle] = useState(place?.title || '')
  const [description, setDescription] = useState(place?.description || '')
  const [gid, setGid] = useState(place?.gid || '')
  const [lon, setLon] = useState(place?.location?.x.toString() || '')
  const [lat, setLat] = useState(place?.location?.y.toString() || '')
  const [visited, setVisited] = useState(place?.visited || false)
  const [image, setImage] = useState(null)
  const preview = image ? image.preview : (id && `${API_URL}/places/${place.id}/image.jpg`)
  const handleImage = e => {
    const f = e.target.files[0]
    setImage(f ? { file: f, preview: URL.createObjectURL(f) } : null)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const place: Place = {
        id,
        title,
        description,
        gid,
        location: {
          x: parseFloat(lon),
          y: parseFloat(lat)
        },
        visited
      }
      let body: any = place
      if (image) {
        body = new FormData()
        body.append('image', image.file)
        body.append('place', JSON.stringify(place))
      }
      const result = id ? await placeCRUD.update(id, body) : await placeCRUD.create(body)
      history.push('/place/' + result.id)
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <div className="page place-edit">
      <header>
        <Link to={id ? '/place/' + id : '/'}>← Volver</Link>
        <h1>{id ? 'Editar punto' : 'Crear punto'}</h1>
        <div />
      </header>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Título:</span>
          <input
            name="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            required
            autoComplete="off"
          />
        </label>
        <label>
          <span>Descripción:</span>
          <textarea
            name="description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            required
          />
        </label>
        <label className="image">
          <span>Imagen:</span>
          <div className="preview" style={preview ? { backgroundImage: `url(${preview})`} : null}>
            <input
              name="image"
              type="file"
              accept="image/jpeg"
              onChange={handleImage}
              required={!id}
            />
          </div>
        </label>
        <label className="place-id">
          <span>
            <span>Place ID:</span>
            <a href="https://developers.google.com/maps/documentation/places/web-service/place-id">Obtener...</a>
          </span>
          <input
            value={gid}
            onChange={e => setGid(e.target.value)}
          />
        </label>
        <label className="location">
          <span>Coordenadas:</span>
          <div>
            <input value={lat} onChange={e => setLat(e.target.value)} placeholder="42.1234567" required />
            <input value={lon} onChange={e => setLon(e.target.value)} placeholder="-8.1234567" required />
          </div>
        </label>
        <label className="visited">
          <span>Visitado:</span>
          <input
            type="checkbox"
            checked={visited}
            onChange={e => setVisited(e.target.checked)}
          />
        </label>
        <button className="primary">Save</button>
      </form>
    </div>
  )
}

const PlaceEditWrapper = () =>
  <Suspense fallback={false}>
    <PlaceEdit />
  </Suspense>

export default PlaceEditWrapper
