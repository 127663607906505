declare var __SERVER__: boolean

const user = __SERVER__
  ? require('./reducers/user.server').default
  : require('./reducers/user.client').default

export const reducerList = {
  user
}

export default reducerList
