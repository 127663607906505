import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import './PlaceList.sass'

const API_URL = (process.env.APP_URL || 'http://localhost:3000') + '/api'

interface PlaceListProps {
  places: Place[]
  linkPrefix?: string
}

const PlaceList = ({ places, linkPrefix = '/place/' }: PlaceListProps) => {
  return (
    <div className="place-list">
      {places.map(p =>
        <Link key={p.id} className="place-entry" to={linkPrefix + p.id}>
          <div className="info">
            <div className="title">
              <span>{p.title}</span>
              <div className={'badge ' + (p.visited ? 'visited' : 'not-visited')}>
                <FormattedMessage id={'badge.' + (p.visited ? 'visited' : 'not-visited')} />
              </div>
            </div>
            <div className="description">
              {p.description.replace(/\n+/g, '\n')}
            </div>
          </div>
          <div
            className="image"
            style={{ backgroundImage: `url(${API_URL}/places/${p.id}/image.jpg)` }}
          />
        </Link>
      )}
      {!places.length &&
        <div className="no-results">
          <FormattedMessage id="home.no-results" />
        </div>
      }
    </div>
  )
}

export default PlaceList
