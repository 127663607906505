import { useCRUD, useFetch, useFetchUncachedCb } from '@gluedigital/ruse-fetch-extras'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from 'src/store/actions/user'

const API_URL = (process.env.APP_URL || 'http://localhost:3000') + '/api'

export const useUserActions = () => {
  const doFetch = useFetchUncachedCb()
  const dispatch = useDispatch()
  return {
    login: async (username: string, password: string) => {
      const res = await doFetch<any>(API_URL + '/login', { body: { username, password }})
      dispatch(login(res))
      return res
    },
    logout: () =>
      doFetch(API_URL + '/logout', { body: {}}),
  }
}

export const usePlaces = (): Place[] =>
  useFetch<Place[]>(API_URL + '/places', undefined, { key: 'places', keep: true })

export const useFilteredPlaces = (q: string): Place[] => {
  const places = usePlaces()
  return useMemo(() => places.filter(placeFilter(q)), [places, q])
}

const placeFilter = (q: string) => {
  const words = q.toLowerCase().split(' ')
  return (place: Place) => {
    const haystack = [place.title, place.description].join(' ').toLowerCase()
    return words.every(w => haystack.includes(w))
  }
}

export const usePlace = (id: number): Place =>
  useFetch<Place>(id && (API_URL + '/places/' + id))

export const useLoggedIn = (): boolean => useSelector(s => !!s.user?.token)

export const usePlaceCRUD = () => useCRUD<number, Place>(API_URL + '/places')
