import React, { Suspense, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PlaceList from 'src/components/PlaceList/PlaceList'
import Search from 'src/components/Search/Search'
import { useFilteredPlaces } from 'src/hooks/api'
import LoginOrCreate from './LoginOrCreate'
import './Home.sass'

const Home = () => {
  const [q, setQ] = useState('')
  const places = useFilteredPlaces(q)

  return (
    <div className="page home">
      <header>
        <h1><FormattedMessage id="home.title" /></h1>
        <LoginOrCreate />
      </header>
      <Search value={q} onChange={setQ} />
      <PlaceList places={places} />
    </div>
  )
}

const HomeWrapper = () =>
  <Suspense fallback={false}>
    <Home />
  </Suspense>

export default HomeWrapper
