import React from 'react'
import { Link } from 'react-router-dom'
import { useLoggedIn } from 'src/hooks/api'

const LoginOrCreate = () => {
  const isLoggedIn = useLoggedIn()
  return (
    <aside className="header-action">
      {isLoggedIn &&
        <Link to="/place/new">
          <button className="primary">+ Add new</button>
        </Link>
      }
      {!isLoggedIn &&
        <Link to="/login">
          <button>Log in</button>
        </Link>
      }
    </aside>
  )
}

export default LoginOrCreate
