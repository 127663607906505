import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useLoggedIn } from 'src/hooks/api'

const LoginOrEdit = () => {
  const isLoggedIn = useLoggedIn()
  const { id } = useParams()
  return (
    <aside className="header-action">
      {isLoggedIn &&
        <Link to={'/place/' + id + '/edit'}>
          <button className="primary">Edit</button>
        </Link>
      }
      {!isLoggedIn &&
        <Link to="/login">
          <button>Log in</button>
        </Link>
      }
    </aside>
  )
}

export default LoginOrEdit
